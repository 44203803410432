<template>
  <div class="fwtc">
    <div class="main">
      <div v-for="item in taocan_order" :key="item.id">

        <div class="info" v-if="item.type == '1'">
          <div class="label">
            <div class="top">
              <van-button size="mini" type="primary">{{ item.taocan_data?.store_data?.store_name || '暂无门店' }}</van-button>
            </div>
            <div class="title">{{ item.taocan_data.mc }}</div>
          </div>
          <div class="date">有效期至:{{ item.dqsj.substring(0, 10) }}</div>
        </div>
        <div class="box" v-if="item.type == '2'">
          <div class="head " :class="{ 'van-hairline--bottom': item.open }">
            <div class="title">
              <div class="top">
                <van-button size="mini" type="primary">{{ item.taocan_data?.store_data?.store_name || '暂无门店' }}</van-button>
              </div>
              <div>{{ item.taocan_data.mc }}</div>
            </div>
            <div class="num">
              <span class="red">{{ (item.xfcs - item.sycs) }}</span>
              <span class="sum">/{{ item.xfcs }}</span>
            </div>
            <div class="btn" @click="(item.open = !item.open)">
              <VanIcon name="arrow-up" v-if="item.open" />
              <VanIcon name="arrow-down" v-else />
            </div>
          </div>
          <div class="stores" v-if="item.open">
            <block v-for="(item, idx) in item.xiaofei_data" :key="item.id">
              <div class="storeRow" :border="false">
                <div class="date">{{ `${idx + 1}、${item.input_time.substring(0, 16)}` }}</div>
                <div class="store">{{ item.store_name }}</div>
              </div>
            </block>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions, mapState } from 'vuex';

export default {
  name: 'Fwtc',
  data() {
    return {
    }
  },
  created() {
    this.get_taocan_order({
      sjhm: this.$route.query.sjhm,
      sfbs: this.$route.query.sfbs
    });
  },
  computed: {
    ...mapState(['taocan_order']),
  },
  methods: {
    ...mapActions(['get_taocan_order']),
  },

}
</script>
<style lang="less" scoped>
.fwtc {
  .info {
    margin: 12px 12px;
    border-radius: 10px;
    background: #fff;
    padding: 12px 12px;
    padding-top:0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .label {
      font-size: 20px;
      color: #333;
    }

    .date {
      font-size: 16px;
      color: red;
    }
  }

  .main {
    margin-top: 24px;

    .box {
      margin: 12px;
      background: #fff;
      padding: 12px;
      padding-top:0;
      border-radius: 10px;

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        // padding-bottom: 12px;

        .title {
          font-size: 20px;
          color: #333;
          flex: 1;
        }

        .num {
          margin-left: 12px;

          .red {
            font-size: 28px;
            color: red;
          }

          .sum {
            font-size: 18px;
          }
        }

        .btn {
          margin-left: 12px;
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:active {
            background: #f4f4f4;
            border-radius: 5px;
          }
        }


      }


      .storeRow {
        margin-top: 6px;
        margin-bottom: 6px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .date {
          font-size: 15px;
          color: #333;
          flex: 1 0 auto;
          min-width: 50%;
        }

        .store {
          font-size: 15px;
          color: #666;
          text-align: right;
          flex: 1 0 auto;
          min-width: 50%;
          max-width: 100%;
        }
      }
    }
  }
}
</style>
